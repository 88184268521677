export enum SettingCodes {
  COMPANY_LOGO_PATH = "leasing_company/logo",
  DOWN_PAYMENT_SETTING_KEY = "down_payment",
  CONTRACT_CUSTOMERS_BANK_INFO = "contract_customers_bank_info",
  SPLIT_LEASING_TEMPLATE = "split_leasing_template",
  RESPONSIBLE_DEPARTMENT = "responsible_department",
  COMPANY_NAME = "company_name",
  COMPANY_CITY = "company_city",
  POSTAL_CODE = "postal_code",
  PHONE_NUMBER = "phone_number",
  CVR = "cvr",
  PERSONAL_CAR_TAX_REG_UPPER_LIMIT = "personal_car_tax_registration_upper_limit",
  MOTORCYCLE_TAX_REG_UPPER_LIMIT_1 = "motorcycle_tax_registration_upper_limit_1",
  MOTORCYCLE_TAX_REG_UPPER_LIMIT_2 = "motorcycle_tax_registration_upper_limit_2",
  INSTALLMENTS_FIRST_OF_MONTH = "installments_first_of_month",
  REG_TAX_INTEREST_RATE = "registration_tax_interest_rate",
  DEPRECIATION_ANNUAL_RATE = "depreciation_annual_rate",
  MAX_SELF_FINANCING_RATE = "maximum_self_financing_rate",
  MIN_REQUIRED_PROFIT = "minimum_required_profit",
  DEFAULT_CONTRACT_SETUP_FEE = "default_contract_setup_fee",
  INTEREST_RATE = "interest_rate",
  DOWN_PAYMENT_PERCENT = "down_payment_percent",
  DEPOSIT_PERCENT = "deposit_percent",
  PROFIT_ON_VEHICLE_PERCENT = "profit_on_vehicle_percent",
  FIRMA_LOGO = "firma_logo",
  SELL_VEHICLE_WITH_VAT = "sell_vehicle_with_vat",
  SELL_VEHICLE_WITH_USED_VAT = "sell_vehicle_with_used_vat",
  TRACKER_PROVIDER = "mileage_tracker_provider",
  TRACKER_PROVIDER_KEY = "mileage_tracker_provider_key",
  DIGITAL_SIGNATURE = "digital_signature",
  COMPANY_SETTINGS = "company_settings",
  LAYOUT_NUMBER = "layout_number",
  FIRST_PAYMENT_LAYOUT_NUMBER = "first_payment_layout_number",
  JOURNAL_NUMBER = "journal_number",
  CONTRACT_ACCOUNTS = "contract_accounts",
  MARKUP_INTEREST_RATE = "markup_interest_rate",
  BENCHMARK_INTEREST_RATE = "benchmark_interest_rate",
  VARIABLE_INTEREST = "variable_interest",
  MONTHIO_SETTINGS = "monthio_settings",
  INCOME_DOCUMENT_EXPIRATION = "income_document_expiration",
  REQUIRED_FIELDS_SETTINGS = "required_fields_settings",
  PRODUCT_ADJUSTMENT_CALCULATION = "product_adjustment_calculation",
  DEFAULT_BULK_INOVICE_HEADER = "default_bulk_invoice_header",
  TEMPLATE_CUSTOM_FIELDS_SETTINGS = "template_custom_fields_settings",
  CREDITRO_SETTINGS = "creditro_settings",
  FARPAY_SETTINGS = "farpay_settings",
  COMPANY_EMAILS = "company_emails",
  DBI_SETTINGS = "dbi_settings",
  TRACKME_SETTINGS = "trackme_settings",
}
