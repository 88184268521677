import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { API } from "../../../../core/_base/crud";
import { IRequestPagination } from "../../../../core/_base/crud/interfaces/request-pagination";
import { IResponsePagination } from "../../../../core/_base/crud/interfaces/response-pagination";
import { ReverseSellVehicleRequest } from "../../contracts/contract-details/reverse-sell-vehicle/reverse-sell-vehicle-request";
import { VehicleModel } from "../../../shared/vehicle-form/vehicle.model";
import { IResponse } from "../../../../core/_base/crud/interfaces/response-interface";

@Injectable()
export class VehiclesService {
  constructor(private http: HttpClient) {}

  createProduct(product): Observable<any> {
    return this.http.post<any>(API.vehicles, product);
  }

  getProductById(productId: number): Observable<any> {
    return this.http.get<any>(API.vehicles + `/${productId}`);
  }

  indexProduct(params: IRequestPagination): Observable<any> {
    return this.http.get<IResponsePagination<VehicleModel>>(API.vehicles, {
      params: { ...params },
    });
  }

  updateProduct(product, productId): Observable<any> {
    return this.http.post<any>(`${API.vehicles}/${productId}`, product);
  }

  deleteProduct(productId: number): Observable<any> {
    return this.http.delete<any>(`${API.vehicles}/${productId}`);
  }

  getLookups(category_code): Observable<any> {
    return this.http.get<any>(`${API.lookups}?category_code=${category_code}`);
  }

  getVehicleMakeDetails(make_id) {
    return this.http.get<any>(`${API.vehiclesMakes}/${make_id}`);
  }

  getVehicleMakeList(q) {
    return this.http.get<any>(`${API.vehiclesMakes}/getAll/list`, {
      params: {
        q,
        order_column: "name",
        order_dir: "asc",
      },
    });
  }

  getContractsList(vehicle_id: any) {
    return this.http.get<any>(`${API.contracts}/getAll`, {
      params: { vehicle_id },
    });
  }

  getVehicleMileageList(vehicle_id: any, per_page: number = 10) {
    return this.http.get<any>(`${API.vehicles}/${vehicle_id}/mileages`, {
      params: {
        per_page,
        "order[column]": "date",
        "order[dir]": "desc",
      },
    });
  }

  getVehicleMarketsList(vehicle_id: any) {
    return this.http.get<any>(`${API.vehicles}/${vehicle_id}/markets/getAll/list`);
  }

  getVehicleMarketPriceSets(vehicle_id: any) {
    return this.http.get<any>(`${API.vehicles}/${vehicle_id}/market-price-sets`);
  }

  getVehicleTransactions(vehicle_id: any, per_page: number) {
    return this.http.get<any>(`${API.transactions}/getAll/list`, {
      params: {
        order_column: "date",
        order_dir: "asc",
        vehicle_id,
        per_page,
      },
    });
  }

  postVehicleRegistrationTaxDocuments(vehicle_id: any, formData: FormData) {
    return this.http.post<any>(`${API.vehicles}/${vehicle_id}/reg-docs`, formData);
  }

  getVehicleRegistrationTaxDocuments(vehicle_id: number | string) {
    return this.http.get<any>(`${API.vehicles}/${vehicle_id}/reg-history`);
  }

  downloadRegistrationTaxDocument(vehicleRegistrationId: number) {
    return this.http.get(`${API.vehicles}/${vehicleRegistrationId}/reg-doc-download`);
  }

  deleteTransactionSet(productIds, id) {
    return this.http.delete<any>(`${API.transactionSets}/delete`, {
      body: { vehicle_id: id, transaction_set_ids: productIds },
    });
  }

  reverseTransactionSet(productIds, id) {
    return this.http.post<any>(`${API.transactionSets}/reverse`, { vehicle_id: id, transaction_set_ids: productIds });
  }

  downloadTransactionsFiles(transaction_set_id) {
    return this.http.get(`${API.transactions}/download-files`, {
      params: { transaction_set_id },
    });
  }

  downloadVehicleMarketPriceSetDoc(vehicle_id, market_price_set_id) {
    return this.http.post(
      `${API.vehicles}/${vehicle_id}/download/market-price-sets-docs`,
      { market_price_set_id },
      {
        responseType: "blob",
      }
    );
  }

  getContractPayments(contract_id: any) {
    let params = new HttpParams();
    params = params.append("contract_id", contract_id);
    params = params.append("type", "vehicle");
    return this.http.get<any>(`${API.payments}/getAll/list`, {
      params: params,
    });
  }

  getVehiclePayments(vehicle_id: number, per_page: number) {
    return this.http.get<any>(`${API.vehiclePayments}/getAll/list`, {
      params: {
        order_column: "date",
        order_dir: "asc",
        vehicle_id: vehicle_id,
        per_page,
      },
    });
  }

  getContractTransactions(contract_id: any) {
    let params = new HttpParams();
    //params = params.append('contract_id', contract_id);
    params = params.append("type", "vehicle");
    return this.http.get<any>(`${API.transactions}/getAll/list`, {
      params: params,
    });
  }

  getVehicleInvoice(vehicle_id: any, per_page: number) {
    return this.http.get<any>(`${API.vehicleInvoices}/getAll/list`, {
      params: {
        order_column: "date",
        order_dir: "asc",
        vehicle_id: vehicle_id,
        per_page,
      },
    });
  }

  downloadVehicleInvoiceDetails(invoice_id) {
    return this.http.get(`${API.vehicleInvoices}/${invoice_id}/download`, {
      responseType: "blob",
    });
  }

  reverseSellVehicle(vehicleId: number, payload?: ReverseSellVehicleRequest) {
    return this.http.post<any>(API.vehicles + `/${vehicleId}/reverse-sell-vehicle`, payload);
  }

  deleteVehiclePayment(paymentId: number) {
    return this.http.delete<any>(API.vehiclePayments + `/${paymentId}`);
  }

  massDeleteVehiclePayments(payments: number[]) {
    return this.http.post<any>(API.vehiclePayments + `/mass-delete`, { payments });
  }

  updateVehicleLocation(location: string, vehicleId: number) {
    return this.http.post<any>(`${API.vehicles}/${vehicleId}/location`, { location });
  }

  reverseVehiclePayments(payments: number[]) {
    return this.http.post<any>(API.vehiclePayments + `/reverse-payments`, { payments });
  }

  getVehicleFromDBI(vinNumber: number): Observable<IResponse<VehicleModel>> {
    return this.http.get<IResponse<VehicleModel>>(`${API.vehicles}/get-vehicle-from-dbi`, {
      params: {
        vinNumber,
      },
    });
  }
}
