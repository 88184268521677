import { MatDialog } from "@angular/material/dialog";
import { LayoutUtilsService } from "app/core/_base/crud";
import { Subscription } from "rxjs";
// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from "./core/_base/layout";
// language list
// import { locale as enLang } from './core/_config/i18n/en';
// import { locale as seLang } from './core/_config/i18n/se';
// import { locale as deLang } from './core/_config/i18n/de';
import { environment } from "../environments/environment";
import { locale as dkLang } from "./core/_config/i18n/da";
import { APP_VERSION } from "./core/constants/local-storage-constants";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "body[kt-root]",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  // Public properties
  title = "LEMA";
  loader: boolean;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  appVersion = environment.appVersion;

  /**
   * Component constructor
   *
   * @param translationService: TranslationService
   * @param router: Router
   * @param matDialog: MatDialog
   * @param layoutConfigService: LayoutCongifService
   * @param splashScreenService: SplashScreenService
   * @param layoutUtilService: LayoutUtilsService
   */
  constructor(
    private translationService: TranslationService,
    private router: Router,
    private titleService: Title,
    private matDialog: MatDialog,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
    private layoutUtilService: LayoutUtilsService
  ) {
    // register translations
    // this.translationService.loadTranslations(enLang, dkLang, seLang,deLang);
    this.translationService.loadTranslations(dkLang);
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    // enable/disable loader
    this.loader = this.layoutConfigService.getConfig("loader.enabled");
    this.clearLocalStorageOnNewVersion();
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // close all dialogs
        this.matDialog.closeAll();
        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add("kt-page--loaded");
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
    this.layoutUtilService.listenToScrollingOnMatDialogOpen();
  }

  ngAfterViewInit() {
    this.titleService.setTitle(`${environment.AppName} | ${window.location.hostname.split(".")[0]}`);
  }

  clearLocalStorageOnNewVersion() {
    if (!localStorage.getItem(APP_VERSION) || this.appVersion !== localStorage.getItem(APP_VERSION)) {
      localStorage.clear();
      localStorage.setItem(APP_VERSION, this.appVersion);
    }
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
