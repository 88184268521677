import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { NgxPermissionsModule } from "ngx-permissions";
import { OnlyOneErrorPipe } from "../../core/_base/layout/pipes/only-one-error.pipe";
import { CoreModule } from "../../core/core.module";
import { PartialsModule } from "../partials/partials.module";
import { CustomerEditComponent } from "./../pages/customers/customer-edit.component";
import { AddComparableVehicleComponent } from "./add-comparable-vehicle/add-comparable-vehicle.component";
import { AddVehicleDocumentComponent } from "./add-vehicle-document/add-vehicle-document.component";
import { AmortizationsModule } from "./amortizations/amortizations.module";
import { CalculationResultsComponent } from "./calculation-results/calculation-results.component";
import { ContractFromOfferComponent } from "./contract-from-offer/contract-from-offer.component";
import { CustomerStepComponent } from "./contract-from-offer/customer-step/customer-step.component";
import { VehicleStepComponent } from "./contract-from-offer/vehicle-step/vehicle-step.component";
import { ContractMileageModule } from "./contract-mileage-list/contract-mileage.module";
import { CustomerFromComponent } from "./customer-form/customer-form.component";
import { ExtendDateComponent } from "./extend-contract/extend-date.component";
import { ExtraEquipmentDialogComponent } from "./extra-equipment/extra-equipment-dialog.component";
import { InvoicesModule } from "./invoices/invoices.module";
import { LeasingCalculatorComponent } from "./leasing-calculator/leasing-calculator.component";
import { MarkTaskDoneDialogComponent } from "./mark-task-as-done/mark-task-done-dialog-component";
import { MultiDropdownMenuComponent } from "./multi-dropdown-menu/multi-dropdown-menu.component";
import { NoteAndFieldsDialogComponent } from "./notes/note-and-fields-dialog.component";
import { NotesComponent } from "./notes/notes.component";
import { PaymentsModule } from "./payments/payments.module";
import { RegistrationFeesComponent } from "./registration-fees/registration-fees.component";
import { SharedService } from "./shared.service";
import { TransactionsModule } from "./transactions/transactions.module";
import { VehicleFormComponent } from "./vehicle-form/vehicle-form.component";
import { VehicleValuationComponent } from "./vehicle-valuation/vehicle-valuation.component";
import { SettingsServicesModule } from "./settings/settings-services.module";
import { CustomersFormGroupComponent } from "./customers-form-group/customers-form-group.component";
import { PermissionsService } from "../pages/permissions";
import { NoScrollDirective } from "../../core/_base/layout/directives/no-scroll.directive";
import { SettingsService } from "../pages/settings/settings.service";
import { MatTabsModule } from "@angular/material/tabs";
import { CreditroCustomersFormArrayComponent } from "./creditro-customers-form-array/creditro-customers-form-array.component";
import { ImportExternalVehicleDialogComponent } from "./import-external-vehicle-dialog/import-external-vehicle-dialog.component";
import { AttachSubCustomersComponent } from "./attach-sub-customers/attach-sub-customers.component";

@NgModule({
  declarations: [
    VehicleFormComponent,
    CustomerFromComponent,
    CustomerEditComponent,
    VehicleValuationComponent,
    ExtraEquipmentDialogComponent,
    LeasingCalculatorComponent,
    CalculationResultsComponent,
    AddComparableVehicleComponent,
    RegistrationFeesComponent,
    NoteAndFieldsDialogComponent,
    ExtendDateComponent,
    NotesComponent,
    MarkTaskDoneDialogComponent,
    AddVehicleDocumentComponent,
    MultiDropdownMenuComponent,
    ContractFromOfferComponent,
    CustomerStepComponent,
    VehicleStepComponent,
    OnlyOneErrorPipe,
    CustomersFormGroupComponent,
    CreditroCustomersFormArrayComponent,
    ImportExternalVehicleDialogComponent,
    AttachSubCustomersComponent,
  ],
  imports: [
    CommonModule,
    PartialsModule,
    TransactionsModule,
    InvoicesModule,
    PaymentsModule,
    AmortizationsModule,
    ContractMileageModule,
    NgxPermissionsModule,
    CoreModule,
    MatDividerModule,
    MatIconModule,
    MatTabsModule,
  ],
  exports: [
    VehicleFormComponent,
    VehicleValuationComponent,
    CustomerFromComponent,
    CustomerEditComponent,
    ExtraEquipmentDialogComponent,
    LeasingCalculatorComponent,
    ExtendDateComponent,
    CalculationResultsComponent,
    AddComparableVehicleComponent,
    RegistrationFeesComponent,
    TransactionsModule,
    SettingsServicesModule,
    InvoicesModule,
    PaymentsModule,
    AmortizationsModule,
    NoteAndFieldsDialogComponent,
    NotesComponent,
    MarkTaskDoneDialogComponent,
    MultiDropdownMenuComponent,
    MatDividerModule,
    ContractFromOfferComponent,
    CustomerStepComponent,
    VehicleStepComponent,
    MatIconModule,
    OnlyOneErrorPipe,
    CustomersFormGroupComponent,
    CreditroCustomersFormArrayComponent,
    NoScrollDirective,
    ImportExternalVehicleDialogComponent,
  ],
  providers: [SharedService, PermissionsService, NoScrollDirective, SettingsService],
})
export class SharedModule {}
